import moment from 'moment'
import { assertBoom } from 'lib/assertBoom'
import { debug, logWarn } from 'lib/logging'

export function setCookie<T>(
  name: string,
  value: T,
  expires: string = moment.utc().add(10, `years`).toString(),
  path = `/`,
) {
  debug([`cookie`, `cookie-set`], `setCookie`, name, value, expires)
  assertBoom(value != null, `cookie value is required`, `badRequest`)
  assertBoom(moment(expires).isValid(), `expires value not valid`, `badRequest`)
  let val = `${name}=${JSON.stringify(value)}; Expires=${expires}; Path=${path};`

  if (val.length > 1280) {
    logWarn(`[cookie]`, name, `cookie too large`, val.length)
    return
  }

  document.cookie = val
}
